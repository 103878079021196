@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');

body, html {
    font-size: 10px;
    background: var(--color-main);
    text-align: center;
}

:root {
    /*Colors*/
    --color-text: #535f61;
    --color-main: rgb(10, 25, 47);
    --color-secondary: rgb(100, 255, 218);
    --color-ter: #F25F5C;

    --color-light: #F1FAEE;
  
    --color-cta: #f18970;
  
    --color-confirmation: #1d731d;
    
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
  }

*::selection {
  background: var(--color-secondary);
}

  
*,
*::before,
*::after {
  box-sizing: border-box;
  font-weight: 300;
  line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

h1 {
    font-size: 4.2em;
}

h2 {
  color: var(--color-light);
  font-size: 2.8em;
  margin-bottom: 3rem;
}

h3 {
  font-size: 2.2em;
}

ul {
  li {
    margin-bottom: 0;
  }
}

p, li {
    font-size: 1.8em;
    color: var(--color-light);
    margin-bottom: 1.5rem;
    line-height: 1.5;

    a {
      font: inherit;
      color: var(--color-cta);
      transition: all ease-in-out 0.2s;
      font-weight: 400;

      span {
        text-decoration: underline;
        font: inherit;
      }

      &:hover {
        opacity: 0.8;
      }
    }
}

.container {
    max-width: 115rem;
    margin: 0 auto;
    width: calc(100% - 10rem);

    &.container-small {
      max-width: 78rem;
    }
}

.highlight {
  color: var(--color-secondary);
  font-weight: 400;
}

.highlight-secondary {
  color: var(--color-cta);
  font-weight: 700;
}

.highlight-ter {
  color: var(--color-ter);
}

.explosion {
  transition: all linear 0.5s 0.35s !important;
  position: relative;
  display: inline-block;
  transform: translate(0);
  font-weight: inherit;
}

.diffused {
  .explosion {
      opacity: 1;
      transform: none !important;
  }
}

.emoji {
  font-size: 1.8em;
  vertical-align: middle;
}

section {
  padding: 5rem 0;
}

svg {
  fill: var(--color-light);
  max-width: 30rem;
  overflow: visible;
}

.notfound {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: var(--color-secondary);
    font-size: 12.8em;
    letter-spacing: 20px;
    font-style: italic;

  }

  p {
    letter-spacing: 5px;
    font-size: 2.4em;

    &.link {
      letter-spacing: 1.5px;
      font-size: 1.6em;
    }
  }
}

@media only screen and (max-width: 560px) {
  body, html {
    font-size: 8px;
  }

  .container {
    width: 92.5%;
  }
}