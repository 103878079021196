footer {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 2rem 1.5rem;
    z-index: 9999;
    
    &:after {
        content: '';
        height: 25vh;
        width: 1px;
        background-color: var(--color-light);
        opacity: 0.4;
        display: block;
        margin: 3rem auto 0;
    }

    li {
        display: block;
        margin-top: 1.5rem;
        
        a {
            transition: none;
            opacity: 1;

            &:hover {
                opacity: 1;
            }

            svg {
                fill: var(--color-light);
                opacity: 0.6;
                transition: all ease-in-out 0.2s;
                width: 2rem;
            }

            &:hover {
                svg {
                    transform: scale(1.1);
                    fill: var(--color-secondary);
                    opacity: 1;
                }
            }
        }
    }
}

@media only screen and (max-width: 560px) {
    footer {
        position: sticky;
        bottom: 0;
        left: 0;
        background: var(--color-main);
        padding: 2rem 0;
        border-top: 1px solid rgba(255,255,255,0.1);
        width: 100%;
        
        
        &:after {
            display: none;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
                margin: 0 1rem;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
    }
  }