@import "https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
}

body, html {
  background: var(--color-main);
  text-align: center;
  font-size: 10px;
}

:root {
  --color-text: #535f61;
  --color-main: #0a192f;
  --color-secondary: #64ffda;
  --color-ter: #f25f5c;
  --color-light: #f1faee;
  --color-cta: #f18970;
  --color-confirmation: #1d731d;
  letter-spacing: 1px;
  font-family: Lato, sans-serif;
}

::selection {
  background: var(--color-secondary);
}

*, :before, :after {
  box-sizing: border-box;
  font-weight: 300;
  line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

h1 {
  font-size: 4.2em;
}

h2 {
  color: var(--color-light);
  margin-bottom: 3rem;
  font-size: 2.8em;
}

h3 {
  font-size: 2.2em;
}

ul li {
  margin-bottom: 0;
}

p, li {
  color: var(--color-light);
  margin-bottom: 1.5rem;
  font-size: 1.8em;
  line-height: 1.5;
}

p a, li a {
  font: inherit;
  color: var(--color-cta);
  font-weight: 400;
  transition: all .2s ease-in-out;
}

p a span, li a span {
  font: inherit;
  text-decoration: underline;
}

p a:hover, li a:hover {
  opacity: .8;
}

.container {
  width: calc(100% - 10rem);
  max-width: 115rem;
  margin: 0 auto;
}

.container.container-small {
  max-width: 78rem;
}

.highlight {
  color: var(--color-secondary);
  font-weight: 400;
}

.highlight-secondary {
  color: var(--color-cta);
  font-weight: 700;
}

.highlight-ter {
  color: var(--color-ter);
}

.explosion {
  font-weight: inherit;
  display: inline-block;
  position: relative;
  transform: translate(0);
  transition: all .5s linear .35s !important;
}

.diffused .explosion {
  opacity: 1;
  transform: none !important;
}

.emoji {
  vertical-align: middle;
  font-size: 1.8em;
}

section {
  padding: 5rem 0;
}

svg {
  fill: var(--color-light);
  max-width: 30rem;
  overflow: visible;
}

.notfound {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.notfound h1 {
  color: var(--color-secondary);
  letter-spacing: 20px;
  font-size: 12.8em;
  font-style: italic;
}

.notfound p {
  letter-spacing: 5px;
  font-size: 2.4em;
}

.notfound p.link {
  letter-spacing: 1.5px;
  font-size: 1.6em;
}

@media only screen and (width <= 560px) {
  body, html {
    font-size: 8px;
  }

  .container {
    width: 92.5%;
  }
}

.banner {
  background: var(--color-main);
  color: var(--color-light);
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 7.5rem 0;
  display: flex;
  overflow: hidden;
}

.banner .explosion-container {
  width: 700px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.banner .container {
  position: relative;
}

.banner .container .banner-profile {
  z-index: 99;
  position: relative;
}

.banner .container .banner-content {
  pointer-events: none;
  margin-top: -30rem;
  transition: all .3s ease-in-out;
}

.banner .container.diffused h1:after {
  transition: all .5s cubic-bezier(.645, .045, .355, 1) .5s;
  transform: scale(1);
}

.banner .container.diffused .banner-content {
  pointer-events: all;
  max-height: 100rem;
  margin-top: 0;
}

.banner .container.diffused .banner-profile .banner-profile-container {
  z-index: 999;
  transition: all .6s cubic-bezier(.645, .045, .355, 1);
  position: relative;
  top: 0;
}

.banner .container.diffused .banner-profile .banner-profile-container .profile-content {
  opacity: 0;
  max-height: 0;
  transition: all .5s cubic-bezier(.645, .045, .355, 1);
  transform: translateY(-10rem)scale(0);
}

.banner .container.diffused .banner-profile .banner-profile-container .profile-image {
  margin-top: 0;
  transition: margin .2s linear .4s;
}

.banner-profile-container {
  margin: 0 auto 3rem;
  transition: all .4s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
}

.banner-profile-container .profile-content {
  color: #000;
  opacity: 1;
  border-radius: 2px;
  max-width: 78rem;
  max-height: 100rem;
  margin: 0 auto;
  font-size: 7px;
  transition: all .6s cubic-bezier(.645, .045, .355, 1);
  transform: translateY(0)scale(1);
}

@media only screen and (width <= 780px) {
  .banner-profile-container .profile-content {
    font-size: 6px;
  }
}

.banner-profile-container .profile-content p {
  letter-spacing: 2px;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.banner-profile-container .profile-image {
  border: 2px solid var(--color-light);
  background: #fff3;
  border-radius: 50%;
  width: 17.5em;
  height: 17.5em;
  margin-top: 5rem;
  transition: margin .2s linear;
  display: inline-block;
  position: relative;
}

.banner-profile-container .view-about {
  background: var(--color-main);
  border: 2px solid var(--color-light);
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5em;
  font-weight: 700;
  transition: color .15s ease-in-out, border .15s ease-in-out;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.banner-profile-container .view-about:hover {
  color: var(--color-ter);
  border-color: var(--color-ter);
}

.banner-profile-container .view-about span {
  color: var(--color-cta);
  white-space: nowrap;
  opacity: 0;
  padding: 1rem 0;
  font-style: italic;
  font-weight: 400;
  position: absolute;
  top: 200%;
  left: calc(100% + 1rem);
  transform: translateY(-50%)scale(0);
}

.banner-profile-container .view-about.active span {
  opacity: 1;
  top: 50%;
  transform: translateY(-50%)scale(1);
}

.banner-profile-container img {
  border-radius: 50%;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.banner h1 {
  display: inline-block;
}

.banner h1:after {
  content: "";
  background: var(--color-light);
  width: 110%;
  height: 2px;
  margin: 2rem 0;
  transition: all .5s cubic-bezier(.645, .045, .355, 1);
  display: block;
  position: relative;
  left: -5%;
  transform: scale(0);
}

.banner p {
  margin-bottom: 2rem;
  font-size: 2.2em;
}

.banner p.scroll {
  opacity: 1;
  opacity: .3;
  height: 5rem;
  font-size: 5.6em;
  font-weight: 300;
  transition: all .2s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
  margin-top: 3rem !important;
}

.banner p.scroll:before, .banner p.scroll:after {
  content: "˅";
  transform-origin: 0;
  cursor: pointer;
  height: auto;
  font-size: .5em;
  line-height: 1;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 50%;
  transform: scaleX(2.2)translateX(-50%);
}

.banner p.scroll:after {
  font-size: 1.2em;
  animation: .5s linear infinite alternate animateScroll;
}

.banner p.scroll:hover {
  opacity: .8;
}

.banner p:last-child {
  margin: 0;
}

@keyframes animateScroll {
  from {
    top: 0;
  }

  to {
    top: .5rem;
  }
}

@media only screen and (width <= 560px) {
  .view-about.active span {
    width: 100px;
    white-space: initial;
    top: calc(50% + 5px);
  }
}

.timeline-filter {
  background: var(--color-main);
  z-index: 99;
  border-bottom: 1px solid #ffffff1a;
  padding: 2rem 0;
  position: sticky;
  top: 0;
  left: 0;
}

.timeline-filter .filter-border {
  height: 2px;
  transition: all .25s cubic-bezier(.645, .045, .355, 1);
  display: block;
  position: absolute;
}

.timeline-filter .sort {
  cursor: pointer;
  font-weight: bold;
}

.timeline-filter .filter:not(.filter-border) {
  cursor: pointer;
  user-select: none;
  letter-spacing: 1.5px;
  padding: .5rem 0;
  font-weight: 600;
  transition: all .2s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
  top: 0;
}

@media only screen and (width >= 780px) {
  .timeline-filter .filter:not(.filter-border):hover {
    margin-bottom: -.5rem;
    padding-bottom: 1rem;
    top: -.5rem;
  }
}

.timeline-filter .filter:not(.filter-border).active:after {
  transform: scaleX(1);
}

.timeline {
  text-align: center;
  min-height: 100vh;
  padding: 7.5rem 0;
}

.timeline img {
  object-fit: contain;
}

.timeline > div {
  text-align: right;
  opacity: 0;
  opacity: 0;
  width: calc(50% + 1px);
  max-height: 0;
  transition: all .5s cubic-bezier(.645, .045, .355, 1), padding-right, padding-left;
  position: relative;
  top: 10rem;
  overflow: visible;
  transform: scaleY(0);
}

.timeline > div > a {
  text-decoration: none;
  position: relative;
}

.timeline > div > a:after {
  content: "";
  background: var(--color-secondary);
  transform-origin: 50%;
  opacity: .4;
  width: 100%;
  height: 1px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: calc(100% - 2rem);
  transform: scaleX(0);
}

.timeline > div > a:hover:after {
  transform: scaleX(1);
}

.timeline > div > a:hover img {
  transform: scale(1.1);
}

.timeline > div > a img {
  transition: all .2s ease-in-out;
}

.timeline > div .timeline-header, .timeline > div .timeline-technologies {
  flex-wrap: wrap;
  align-items: flex-start;
  display: inline-flex;
}

.timeline > div .timeline-technologies {
  align-items: center;
  margin-top: 3rem;
}

.timeline > div .timeline-technologies p {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: .5rem 2rem;
  font-size: 1.2em;
}

.timeline > div .timeline-logo {
  border-radius: 5px;
  width: 50px;
  margin-top: .1rem;
}

.timeline > div .timeline-logo.studiohub {
  background: linear-gradient(to right, #a36aa5 0% 25%, #8d6cab 50% 75%, #6b7bb6 100%);
  height: 50px;
  padding: 1rem 1.2rem;
}

.timeline > div.active.scroll-active {
  opacity: 1;
  opacity: 1;
  margin-bottom: 6rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  top: 0;
  transform: scale(1);
}

.timeline > div:after, .timeline > div:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeline > div:before {
  width: 2rem;
  height: 2rem;
  box-shadow: 0 0 15px var(--color-secondary);
  background-color: var(--color-main);
  border: 2px solid var(--color-secondary);
}

.timeline > div:after {
  background: var(--color-secondary);
  width: 1rem;
  height: 1rem;
}

.timeline > div.scroll-left {
  border-right: 2px solid var(--color-light);
  transform-origin: 100%;
  padding-right: 5%;
}

.timeline > div.scroll-left .timeline-header {
  flex-direction: row-reverse;
}

.timeline > div.scroll-left .timeline-technologies {
  justify-content: flex-end;
}

.timeline > div.scroll-left .timeline-technologies p {
  margin-left: 1rem;
  margin-right: 0;
}

.timeline > div.scroll-left .timeline-logo {
  margin-left: 1.5rem;
}

.timeline > div.scroll-left:before, .timeline > div.scroll-left:after {
  left: calc(100% + 1px);
  transform: translate(-50%, -50%);
}

.timeline > div.scroll-left ul {
  padding-right: 4rem;
}

.timeline > div.scroll-left li:before, .timeline > div.scroll-left li:after {
  right: -1.75rem;
}

.timeline > div.scroll-right {
  text-align: left;
  border-left: 2px solid var(--color-light);
  transform-origin: 0%;
  margin-left: auto;
  padding-left: 5%;
}

.timeline > div.scroll-right .timeline-logo {
  margin-right: 1.5rem;
}

.timeline > div.scroll-right:before, .timeline > div.scroll-right:after {
  left: -1px;
}

.timeline > div.scroll-right ul {
  padding-left: 4rem;
}

.timeline > div h3 {
  color: var(--color-secondary);
  letter-spacing: 2px;
  flex: 1;
  margin-bottom: 3rem;
  font-weight: 400;
}

.timeline > div h3 span {
  margin-top: .5rem;
  font-size: .6em;
  line-height: 1.5;
  display: block;
}

.timeline > div ul {
  margin-top: 3rem;
}

.timeline > div li {
  opacity: .8;
  margin-bottom: 1rem;
  font-size: 1.4em;
  line-height: 1.5;
  position: relative;
}

.timeline > div li:before, .timeline > div li:after {
  content: "";
  border: .4rem solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: .65rem;
  right: calc(100% + 1.25rem);
}

.timeline > div li:before {
  border-top-color: var(--color-secondary);
  transform: translateY(50%);
}

.timeline > div li:after {
  border-bottom-color: var(--color-secondary);
  transform: translateY(-50%);
}

.timeline > div > p {
  margin-bottom: 1.5rem;
  font-size: 1.6em;
  line-height: 1.5;
}

.timeline > div .subtitle {
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.3em;
  font-weight: 400;
}

.timeline > div.timeline-event > a:after {
  background-color: var(--color-ter);
}

.timeline > div.timeline-event h3 {
  color: var(--color-ter);
}

.timeline > div.timeline-event:after {
  background: var(--color-ter);
}

.timeline > div.timeline-event:before {
  border-color: var(--color-ter);
  box-shadow: 0 0 15px var(--color-ter);
}

.timeline > div.timeline-event .timeline > div li:before {
  border-top-color: var(--color-ter);
}

.timeline > div.timeline-event .timeline > div li:after {
  border-bottom-color: var(--color-ter);
}

.timeline > div.timeline-project > a:after {
  background-color: var(--color-cta);
}

.timeline > div.timeline-project h3 {
  color: var(--color-cta);
}

.timeline > div.timeline-project:after {
  background: var(--color-cta);
}

.timeline > div.timeline-project:before {
  border-color: var(--color-cta);
  box-shadow: 0 0 15px var(--color-cta);
}

.timeline > div.timeline-project li:before {
  border-top-color: var(--color-cta);
}

.timeline > div.timeline-project li:after {
  border-bottom-color: var(--color-cta);
}

.timeline > div.timeline-project .timeline-technologies p {
  border-color: var(--color-cta);
  color: var(--color-cta);
}

@media only screen and (width <= 780px) {
  .timeline div {
    width: 100%;
  }

  .timeline-filter {
    width: 100vw;
    margin-left: -3.75vw;
    padding: 2rem 1rem;
  }
}

@media only screen and (width <= 560px) {
  .timeline > div {
    font-size: 10px;
  }
}

footer {
  z-index: 9999;
  padding: 2rem 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
}

footer:after {
  content: "";
  background-color: var(--color-light);
  opacity: .4;
  width: 1px;
  height: 25vh;
  margin: 3rem auto 0;
  display: block;
}

footer li {
  margin-top: 1.5rem;
  display: block;
}

footer li a {
  opacity: 1;
  transition: none;
}

footer li a:hover {
  opacity: 1;
}

footer li a svg {
  fill: var(--color-light);
  opacity: .6;
  width: 2rem;
  transition: all .2s ease-in-out;
}

footer li a:hover svg {
  fill: var(--color-secondary);
  opacity: 1;
  transform: scale(1.1);
}

@media only screen and (width <= 560px) {
  footer {
    background: var(--color-main);
    border-top: 1px solid #ffffff1a;
    width: 100%;
    padding: 2rem 0;
    position: sticky;
    bottom: 0;
    left: 0;
  }

  footer:after {
    display: none;
  }

  footer ul {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  footer ul li {
    margin: 0 1rem;
  }

  footer ul li a {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  footer ul li svg {
    width: 3rem;
    height: 3rem;
  }
}
/*# sourceMappingURL=index.ca326844.css.map */
