.banner {
    padding: 7.5rem 0;
    background: var(--color-main);
    color: var(--color-light);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .explosion-container {
        width: 700px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .container {
        position: relative;

        .banner-profile {
            z-index: 99;
            position: relative;
        }

        .banner-content {
            margin-top: -30rem;
            transition: all ease-in-out 0.3s 0s;
            pointer-events: none;
        }

        &.diffused {
            h1 {
                &:after {
                    transform: scale(1);
                    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s 0.5s;
                }
            }

            .banner-content {
                max-height: 100rem;
                margin-top: 0;
                pointer-events: all;
            }

            .banner-profile {
                .banner-profile-container {
                    z-index: 999;
                    position: relative;
                    top: 0;
                    transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;

                    .profile-content {
                        transform: translateY(-10rem) scale(0);
                        opacity: 0;
                        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
                        max-height: 0;
                    }

                    .profile-image {
                        margin-top: 0;
                        transition: margin linear 0.2s 0.4s;
                    }
                }
            }
        }
    }

    &-profile-container {
        display: inline-block;
        position: relative;
        margin: 0 auto;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
        margin-bottom: 3rem;

        .profile-content {
            color: black;
            transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
            border-radius: 2px;
            max-width: 78rem;
            opacity: 1;
            font-size: 7px;
            margin: 0 auto;
            max-height: 100rem;
            transform: translateY(0) scale(1);

            @media only screen and (max-width: 780px) {
                font-size: 6px;
            }


            p {
                font-weight: 400;
                margin-bottom: 1.5rem;
                letter-spacing: 2px;
            }
        }

        .profile-image {
            position: relative;
            display: inline-block;
            margin-top: 5rem;
            transition: margin linear 0.2s 0s;
            width: 17.5em;
            height: 17.5em;
            border-radius: 50%;
            border: 2px solid var(--color-light);
            background: rgba(255,255,255,0.2);
        }

        .view-about {
            position: absolute;
            top: 1rem;
            right: 1.5rem;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--color-main);
            border-radius: 50%;
            font-size: 1.5em;
            font-weight: 700;
            border: 2px solid var(--color-light);
            cursor: pointer;
            transition: color ease-in-out 0.15s, border ease-in-out 0.15s;
            user-select: none;
    
            &:hover {
             color: var(--color-ter);
             border-color: var(--color-ter);
            }

            span {
                position: absolute;
                left: calc(100% + 1rem);
                top: 200%;
                transform: translateY(-50%) scale(0);
                color: var(--color-cta);
                font-weight: 400;
                white-space: nowrap;
                font-style: italic;
                padding: 1rem 0;
                opacity: 0;
            }

            &.active {
                span {
                    transform: translateY(-50%) scale(1);
                    opacity: 1;
                    top: 50%;
                }
            }
        }
    
        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            border-radius: 50%;
        }
    }

    h1 {
        display: inline-block;

        &:after {
            content: '';
            display: block;
            height: 2px;
            background: var(--color-light);
            margin: 2rem 0;
            width: 110%;
            position: relative;
            left: -5%;
            transform: scale(0);
            transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s 0s;
        }
    }

    p {
        font-size: 2.2em;
        margin-bottom: 2rem;

        &.scroll {
            opacity: 1;
            font-size: 5.6em;
            transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
            font-weight: 300;
            position: relative;
            opacity: 0.3;
            display: inline-block;
            margin-top: 3rem !important;
            height: 5rem;

            &:before, &:after {
                content: '˅';
                position: absolute;
                left: 50%;
                top: 0;
                height: auto;
                transform: scaleX(2.2) translateX(-50%);
                line-height: 1;
                transform-origin: 0;
                font-size: 0.5em;
                transition: inherit;
                cursor: pointer;
            }

            &:after {
                font-size: 1.2em;
                animation: animateScroll 0.5s linear 0s infinite alternate;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &:last-child {
            margin: 0;
        }
    }
}

@keyframes animateScroll {
    from {
      top: 0;
    }
    to {
      top: 0.5rem;
    }
  }

  @media only screen and (max-width: 560px) {

    .view-about.active {
        span {
            width: 100px;
            white-space: initial;
            top: calc(50% + 5px);
        }
    }
  }