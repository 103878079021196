.timeline-filter {
    position: sticky;
    top: 0;
    left: 0;
    padding: 2rem 0;
    background: var(--color-main);
    z-index: 99;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    
    .filter-border {
        height: 2px;
        display: block;
        position: absolute;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    }

    .sort {
        font-weight: bold;
        cursor: pointer;
    }

    .filter:not(.filter-border) {
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        position: relative;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
        display: inline-block;
        top: 0;
        padding: 0.5rem 0;
        letter-spacing: 1.5px;

        @media only screen and (min-width: 780px) {
            &:hover {
                top: -0.5rem;
                padding-bottom: 1rem;
                margin-bottom: -0.5rem;
            }    
        }

        &.active {
            &:after {
                transform: scaleX(1);
            }
        }
    }
}

.timeline {
    text-align: center;
    padding: 7.5rem 0;
    min-height: 100vh;

    img {
        object-fit: contain;
    }

    > div {
        width: calc(50% + 1px);
        text-align: right;
        position: relative;
        overflow: hidden;
        overflow: visible;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s, padding-right 0s, padding-left 0s;
        transform: scaleY(0);
        top: 10rem;
        opacity: 0;
        max-height: 0;
        opacity: 0;

        > a {
            text-decoration: none;
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background: var(--color-secondary);
                position: absolute;
                top: calc(100% - 2rem);
                transform: scaleX(0);
                transform-origin: 50% 50%;
                transition: all ease-in-out 0.2s;
                opacity: 0.4;
            }

            &:hover {
                &:after {
                    transform: scaleX(1);
                }

                img {
                    transform: scale(1.1);
                }
            }

            img {
                transition: all ease-in-out 0.2s;
            }
        }

        .timeline-header, .timeline-technologies {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .timeline-technologies {
            align-items: center;
            margin-top: 3rem;
            
            p {
                font-size: 1.2em;
                border: 1px solid var(--color-secondary);
                color: var(--color-secondary);
                padding: 0.5rem 2rem;
                margin-right: 1rem;
                margin-bottom: 1rem;
            }
        }

        .timeline-logo {
            width: 50px;
            border-radius: 5px;
            margin-top: 0.1rem;

            &.studiohub {
                height: 50px;
                padding: 1rem 1.2rem;
                background: linear-gradient(
                    to right,
                    #a36aa5 0%,
                    #a36aa5 25%,
                    #8d6cab 50%,
                    #8d6cab 75%,
                    #6b7bb6 100%
                  );
            }
        }

        &.active.scroll-active {
            margin-bottom: 6rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
            top: 0;
            opacity: 1;
            transform: scale(1);
            opacity: 1;
        }

        &:after, &:before {
            content: '';
            position: absolute;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            width: 2rem;
            height: 2rem;
            box-shadow: 0 0 15px var(--color-secondary);
            background-color: var(--color-main);
            border: 2px solid var(--color-secondary);
        }
        
        &:after {
            width: 1rem;
            height: 1rem;
            background: var(--color-secondary);
        }

        &.scroll-left { 
            border-right: 2px solid var(--color-light);
            padding-right: 5%;
            transform-origin: 100% 50%;

            .timeline-header {
                flex-direction: row-reverse;
            }

            .timeline-technologies {
                justify-content: flex-end;

                p {
                    margin-right: 0;
                    margin-left: 1rem;
                }   
            }

            .timeline-logo {
                margin-left: 1.5rem;
            }

            &:before, &:after {
                left: calc(100% + 1px);
                transform: translate(-50%, -50%);
            }

            ul {
                padding-right: 4rem;
            }

            li {
                &:before, &:after {
                    right: -1.75rem;
                }
            }
        }

        &.scroll-right {
            margin-left: auto;
            text-align: left;
            border-left: 2px solid var(--color-light);
            padding-left: 5%;
            transform-origin: 0% 50%;

            .timeline-logo {
                margin-right: 1.5rem;
            }

            &:before, &:after {
                left: -1px;
            }

            ul {
                padding-left: 4rem;
            }
        }

        h3 {
            color: var(--color-secondary);
            margin-bottom: 3rem;
            letter-spacing: 2px;
            font-weight: 400;
            flex: 1;

            span {
                display: block;
                font-size: 0.6em;
                margin-top: 0.5rem;
                line-height: 1.5;
            }
        }

        ul {
            margin-top: 3rem;
        }

        li {
            font-size: 1.4em;
            line-height: 1.5;
            position: relative;
            margin-bottom: 1rem;
            opacity: 0.8;

            &:before, &:after {
                content: '';
                position: absolute;
                right: calc(100% + 1.25rem);
                top: 0.65rem;
                width: 0;
                height: 0;
                border: 0.4rem solid transparent;
            }

            &:before {
                border-top-color: var(--color-secondary);
                transform: translateY(50%);
            }

            &:after {
                border-bottom-color:  var(--color-secondary);
                transform: translateY(-50%);
            }
        }

        > p {
            font-size: 1.6em;
            margin-bottom: 1.5rem;
            line-height: 1.5;
        }

        .subtitle {
            font-size: 1.3em;
            margin-bottom: 1rem;
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        &.timeline {
            &-event {
                > a {
                    &:after {
                        background-color: var(--color-ter);
                    }
                }

                h3 {
                    color: var(--color-ter);
                }
    
                &:after {
                    background: var(--color-ter);
                }
    
                &:before {
                    border-color: var(--color-ter);
                    box-shadow: 0 0 15px var(--color-ter);
                }

                .timeline > div li:before {
                    border-top-color: var(--color-ter);
                }

                .timeline > div li:after {
                    border-bottom-color: var(--color-ter);
                }
            }


            &-project {
                > a {
                    &:after {
                        background-color: var(--color-cta);
                    }
                }

                h3 {
                    color: var(--color-cta);
                }
    
                &:after {
                    background: var(--color-cta);
                }
    
                &:before {
                    border-color: var(--color-cta);
                    box-shadow: 0 0 15px var(--color-cta);
                }

                li:before {
                    border-top-color: var(--color-cta);
                }

                li:after {
                    border-bottom-color: var(--color-cta);
                }

                .timeline-technologies p {
                    border-color: var(--color-cta);
                    color: var(--color-cta);
                }
            }
            
        }
    
    }
}

@media only screen and (max-width: 780px) {
    .timeline {
        div {
            width: 100%;
        }
    }

    .timeline-filter {
        width: 100vw;
        padding: 2rem 1rem;
        margin-left: -3.75vw;
    }
}

@media only screen and (max-width: 560px) {
    .timeline > div {
      font-size: 10px;
    }
  }